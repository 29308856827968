<template>
    <slot />
</template>

<script setup>
    import { AdditiveBlending, Color, Mesh } from 'three';
    import { computed, ref, watchEffect } from 'vue';

    import { toRadians } from '@resn/gozer-math';
    import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';
    import { CustomMaterial } from '@resn/gozer-three/materials';
    import { ShaderPass } from '@resn/gozer-three/passes';
    import {
        usePane,
        useRaf,
        useRafBool,
        useRenderer,
        useRenderRegistration,
        useThreeObject,
    } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import { blurfs, noisefs } from './shaders';
    import { useGlobalAssets } from '~/providers/GlobalAssets';

    const props = defineProps({
        visible: { default: false },
    });

    const { clock, renderer } = useRenderer();

    // const visible = computed(() => props.visible);

    const uInPr = ref(0);
    const active = computed(() => uInPr.value > 0);

    const DEBUG_COLOR = ref(false);
    const DEBUG_UV = ref(false);
    const DEBUG_TEXTURE = ref(false);
    const x = ref(0);
    const y = ref(0);
    const z = ref(0);
    const rx = ref(45);
    const scale = ref(4);
    const uScale1 = ref(0.15);
    const uScale2 = ref(0.15);
    const uSpeed1 = ref(0.13);
    const uSpeed2 = ref(0.13);
    const uAngle1 = ref(0);
    const uAngle2 = ref(2.5);

    const uNoiseSpread = ref(0.018);
    const uNoiseThreshold = ref(0.0);
    const uNoiseCenter = ref(0.075);

    const uBlurIterations = ref(15);
    const uBlurDist = ref(1.13); // 0.3
    // const uColor1 = ref('#87b2ff');
    // const uColor2 = ref('#bd8ef6');
    // const uColor1 = ref('#ff9cca');
    // const uColor2 = ref('#92ff7d');
    const uColor1 = ref('#ff98cd');
    const uColor2 = ref('#ca96ff');

    useGlobalAssets(({ data }) => {
        noisePass.shader.uniforms.uNoise.value = data.noise1;
        blurPass.shader.uniforms.uNoise.value = data.noise1;
        // material.uniforms.uNoise.value = data.noise1;
        // material.uniforms.uNoise.value = data.pNoise;
    });

    const noisePass = new ShaderPass(renderer, {
        shader: new CustomMaterial({
            uniforms: {
                uTime: 0,
                uInPr: 0,
                uScale1: 0,
                uScale2: 0,
                uSpeed1: 0,
                uSpeed2: 0,
                uAngle1: 0,
                uAngle2: 0,
                uNoiseSpread: 0,
                uNoiseThreshold: 0,
                uNoiseCenter: 0,
                uNoise: null,
            },
            fs: noisefs,
            options: { name: 'NoiseShader' },
        }),
    });
    const blurPass = new ShaderPass(renderer, {
        shader: new CustomMaterial({
            uniforms: {
                uTime: 0,
                uInPr: 0,
                uBlurIterations: 0,
                uBlurDist: 0,
                uNoise: null,
                uTex: noisePass.texture,
                uColor1: new Color(uColor1.value),
                uColor2: new Color(uColor2.value),
            },
            fs: blurfs,
            options: {
                name: 'BlurShader',
                // wireframe: true,
                defines: {
                    DEBUG_COLOR: false,
                    DEBUG_UV: false,
                    DEBUG_TEXTURE: false,
                },
            },
        }),
    });

    // Update the material creation
    const material = new CustomMaterial({
        uniforms: {
            uTime: 0,
            uInPr: 0,
            uTex: blurPass.texture,
        },
        fs: `
            varying vec2 vUv;
            uniform sampler2D uTex;
            uniform float uInPr;

            vec4 inverseSRGBTransferOETF( in vec4 value ) {
                return vec4( mix( pow( ( value.rgb + vec3( 0.055 ) ) / 1.055, vec3( 2.4 ) ), value.rgb / 12.92, vec3( lessThanEqual( value.rgb, vec3( 0.04045 ) ) ) ), value.a );
            }
            
            void main() {
                vec4 tex = texture2D(uTex, vUv);
                gl_FragColor = vec4(1.0,0.0,0.0, 1.0);
                gl_FragColor = tex * uInPr;
                // gl_FragColor = tex;
                
                gl_FragColor.rgb = inverseSRGBTransferOETF(gl_FragColor).rgb;

                // #include <tonemapping_fragment>
                // #include <colorspace_fragment>
            }
        `,
        // fs: fs,
        options: {
            depthTest: false,
            depthWrite: false,
            blending: AdditiveBlending,
        },
    });

    const mesh = new Mesh(simplePlaneGeometry, material);
    mesh.renderOrder = -1;

    useThreeObject(mesh, { name: 'AuroraBorealis' });

    useRaf(() => {
        noisePass.render({ final: false });
        blurPass.render({ final: false });

        noisePass.shader.uniforms.uTime.value += clock.getDelta() * 0.1;
        blurPass.shader.uniforms.uTime.value += clock.getDelta() * 0.1;
        material.uniforms.uTime.value += clock.getDelta() * 0.1;
    });

    const onUpdate = () => {
        // material.uniforms.uInPr.value = uInPr.value;
    };

    const show = ({ start = 0, end = 3 } = {}) => {
        const tl = gsap.timeline({ onUpdate });
        tl.to(
            uInPr,
            {
                value: 1,
                duration: 3,
            },
            start
        );
        tl.to(
            uInPr,
            {
                value: 0,
                duration: 2,
            },
            end
        );

        const startTime = 0;
        noisePass.shader.uniforms.uTime.value = startTime;
        blurPass.shader.uniforms.uTime.value = startTime;
        material.uniforms.uTime.value = startTime;
    };

    defineExpose({ show });

    watchEffect(() => {
        noisePass.shader.uniforms.uScale1.value = uScale1.value;
        noisePass.shader.uniforms.uScale2.value = uScale2.value;
        noisePass.shader.uniforms.uSpeed1.value = uSpeed1.value;
        noisePass.shader.uniforms.uSpeed2.value = uSpeed2.value;
        noisePass.shader.uniforms.uAngle1.value = uAngle1.value;
        noisePass.shader.uniforms.uAngle2.value = uAngle2.value;
        noisePass.shader.uniforms.uNoiseSpread.value = uNoiseSpread.value;
        noisePass.shader.uniforms.uNoiseThreshold.value = uNoiseThreshold.value;
        noisePass.shader.uniforms.uNoiseCenter.value = uNoiseCenter.value;

        blurPass.shader.uniforms.uColor1.value.set(uColor1.value);
        blurPass.shader.uniforms.uColor2.value.set(uColor2.value);
        blurPass.shader.uniforms.uBlurIterations.value = uBlurIterations.value;
        blurPass.shader.uniforms.uBlurDist.value = uBlurDist.value;

        mesh.position.set(x.value, y.value, z.value);
        mesh.rotation.set(toRadians(rx.value), 0, 0);
        mesh.scale.setScalar(scale.value);
    });

    watchEffect(() => {
        blurPass.shader.defines.DEBUG_COLOR = DEBUG_COLOR.value;
        blurPass.shader.defines.DEBUG_UV = DEBUG_UV.value;
        blurPass.shader.defines.DEBUG_TEXTURE = DEBUG_TEXTURE.value;
        blurPass.shader.needsUpdate = true;
    });

    watchEffect(() => {
        noisePass.shader.uniforms.uInPr.value = uInPr.value;
        blurPass.shader.uniforms.uInPr.value = uInPr.value;
        material.uniforms.uInPr.value = uInPr.value;
    });

    usePane(
        [
            { name: 'DEBUG_COLOR', value: DEBUG_COLOR },
            { name: 'DEBUG_UV', value: DEBUG_UV },
            { name: 'DEBUG_TEXTURE', value: DEBUG_TEXTURE },
            { name: 'uInPr', value: uInPr, options: { min: 0, max: 1, step: 0.001 } },
            // { name: 'x', value: x, options: { min: 0, max: 0, step: 0.001 } },
            // { name: 'y', value: y, options: { min: -20, max: 20, step: 0.001 } },
            // { name: 'z', value: z, options: { min: -20, max: 20, step: 0.001 } },
            // { name: 'rx', value: rx, options: { min: -90, max: 90, step: 0.001 } },

            {
                name: 'uNoiseSpread',
                value: uNoiseSpread,
                options: { min: 0.0, max: 0.1, step: 0.0001 },
            },
            {
                name: 'uNoiseThreshold',
                value: uNoiseThreshold,
                options: { min: 0, max: 0.01, step: 0.001 },
            },
            {
                name: 'uNoiseCenter',
                value: uNoiseCenter,
                options: { min: 0.0, max: 0.5, step: 0.001 },
            },

            { name: 'scale', value: scale, options: { min: 0.5, max: 20, step: 0.001 } },
            { name: 'uScale1', value: uScale1, options: { min: 0, max: 0.5, step: 0.001 } },
            { name: 'uScale2', value: uScale2, options: { min: 0, max: 0.5, step: 0.001 } },
            { name: 'uSpeed1', value: uSpeed1, options: { min: 0, max: 1, step: 0.001 } },
            { name: 'uSpeed2', value: uSpeed2, options: { min: 0, max: 1, step: 0.001 } },
            { name: 'uAngle1', value: uAngle1, options: { min: 0, max: 6.28, step: 0.001 } },
            { name: 'uAngle2', value: uAngle2, options: { min: 0, max: 6.28, step: 0.001 } },
            { name: 'uColor1', value: uColor1 },
            { name: 'uColor2', value: uColor2 },
            {
                name: 'uBlurIterations',
                value: uBlurIterations,
                options: { min: 0, max: 10, step: 1 },
            },
            { name: 'uBlurDist', value: uBlurDist, options: { min: 0, max: 2, step: 0.001 } },
        ],
        { title: 'Aurora' }
    );
</script>
