import glslNoise from '@/libs/lygia/generative/snoise.glsl';
import glslRotate from '@/libs/lygia/space/rotate.glsl';
import { createNoiseFromTexture } from '~/libs/three/noise-texture';

export const vs = /*glsl*/ `
    
`;

export const noisefs = /*glsl*/ `

    varying vec2 vUv;

    uniform float uTime;
    
    uniform float uScale1;
    uniform float uScale2;
    uniform float uSpeed1;
    uniform float uSpeed2;
    uniform float uAngle1;
    uniform float uAngle2;
    uniform float uInPr;
    uniform float uNoiseSpread;
    uniform float uNoiseThreshold;
    uniform float uNoiseCenter;

    uniform sampler2D uNoise;

    ${createNoiseFromTexture}
    ${glslNoise}
    ${glslRotate}

    void main() {
        vec2 uv = vUv;

        // float noise = texture2D(uNoise, vUv).r;
        
        // Simplex noise
        float speed1 = uTime * uSpeed1;
        float speed2 = uTime * uSpeed2 + 1234.1234;
        float noise = createNoiseFromTexture(uNoise, vUv, speed1, uScale1, uAngle1, speed2, uScale2, uAngle2);
        // float noise = createNoiseFromTexture(uNoise, vUv, speed1, uScale1, uAngle1);
        noise = smoothstep(0.05, 1.0, noise);
        float spread = 0.01;
        float noiseThreshold = 0.2;
        noise = smoothstep(uNoiseThreshold + uNoiseSpread, uNoiseThreshold, abs(noise - uNoiseCenter));
        
        // Math noise
        // float noise = snoise(vec3(vUv.x * uScale1 * 25.0, vUv.y * uScale1 * 25.0, uTime * uSpeed1)) * 0.5 + 0.5;
        // noise = smoothstep(0.05, 0.0, abs(noise - 0.5));

        gl_FragColor = vec4(1.0,0.0,0.0, 1.0);
        gl_FragColor = vec4(vec3(noise), 1.0);
        // gl_FragColor = texture2D(uNoise, vUv);
    }

`;

export const blurfs = /*glsl*/ `
    uniform sampler2D uTex;
    uniform vec3 uColor1;
    uniform vec3 uColor2;
    uniform float uBlurIterations;
    uniform float uBlurDist;
    uniform float uInPr;
    uniform float uTime;

    uniform sampler2D uNoise;

    varying vec2 vUv;

    vec4 verticalBlur(sampler2D tex, vec2 uv) {
        vec2 uDirection = vec2(0.0,1.0);
        vec2 direction = normalize(uDirection) * uBlurDist;
        
        vec4 color = vec4(0.0);
        float totalWeight = 0.0;

        // Set a maximum number of iterations
        const int MAX_ITERATIONS = 8;

        for (int i = -MAX_ITERATIONS; i <= MAX_ITERATIONS; i++) {
            // Break the loop if we've reached the desired number of iterations
            // if (abs(float(i)) > uBlurIterations) break;
            
            float weight = 1.0 - abs(float(i) / float(MAX_ITERATIONS));
            vec2 sampleUv = uv + direction * float(i) * 0.01;
            color += texture2D(tex, sampleUv) * weight;
            totalWeight += weight;
        }
        
        return color / totalWeight;
    }

    void main() {
        float xGradient = 1.0 - abs(vUv.x - 0.5) * 2.0;
        xGradient = smoothstep(0.0, 0.3, xGradient);
        
        float yGradient = 1.0 - abs(vUv.y - 0.5) * 2.0;
        yGradient = smoothstep(0.0, 0.1, yGradient);
        yGradient *= smoothstep(0.0, 1.0, vUv.y);

        vec3 bgGlow = mix(uColor1, uColor2, vUv.x) * 0.4;
        // bgGlow *= smoothstep(0.0, 1.0, vUv.y);
        bgGlow *= xGradient * yGradient;
        // bgGlow *= vUv.y;

        vec4 blurred1 = verticalBlur(uTex, vUv);
        vec4 blurred2 = verticalBlur(uTex, vUv + vec2(0.0, uBlurDist * -0.075));
        vec3 colorOutput = blurred1.rgb * uColor1 + blurred2.rgb * uColor2;

        colorOutput *= xGradient * yGradient;
        colorOutput += bgGlow;

        gl_FragColor = vec4(colorOutput, 1.0);
        // gl_FragColor = vec4(vec3(), 1.0);
        // gl_FragColor = blurred2;
        // gl_FragColor = texture2D(uTex, vUv);

        #ifdef DEBUG_COLOR
            gl_FragColor = vec4(1.0,0.0,0.0, 1.0);
        #endif

        #ifdef DEBUG_UV
            gl_FragColor = vec4(vUv, 1.0, 1.0);
        #endif

        #ifdef DEBUG_TEXTURE
            gl_FragColor = texture2D(uTex, vUv);
        #endif
        
    }

`;
