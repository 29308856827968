import glslRotate from '@/libs/lygia/space/rotate.glsl';

export const createNoiseFromTexture = /* glsl */ `
    ${glslRotate}

    float createNoiseFromTexture(sampler2D noiseTexture, vec2 uv, float time, float scale, float angle) {
        vec2 uv1 = rotate(uv, angle) * scale + time;
        vec2 uv2 = rotate(uv, angle + 3.14) * scale + time;
        
        float noise1 = texture2D(noiseTexture, uv1).r;
        float noise2 = texture2D(noiseTexture, uv2).r;
        float n = mix(noise1, noise2, 0.5);
        
        return n;
    }
    
    float createNoiseFromTexture(sampler2D noiseTexture, vec2 uv, float time1, float scale1, float angle1, float time2, float scale2, float angle2) {
        vec2 uv1 = rotate(uv, angle1) * scale1 + time1;
        vec2 uv2 = rotate(uv, angle2) * scale2 + time2;
        
        float noise1 = texture2D(noiseTexture, uv1).r;
        float noise2 = texture2D(noiseTexture, uv2).r;
        float n = mix(noise1, noise2, 0.5);
        
        return n;
    }
`;

// Better version, use this one
export const createNoiseFromTexture2 = /* glsl */ `
    ${glslRotate}

    float createNoiseFromTexture(sampler2D noiseTexture, vec2 uv, float time, float scale, float angle) {
        
        // Use time to create movement
        vec2 movement = vec2(time, time * 0.5);
        uv = uv * scale;
        
        // Sample noise at different scales and offsets
        float noise1 = texture2D(noiseTexture, uv + movement).r;
        float noise2 = texture2D(noiseTexture, uv * 2.0 - movement * 0.5).r;
        float noise3 = texture2D(noiseTexture, uv * 4.0 + movement * 0.2).r;
        
        // Combine noise samples
        float finalNoise = noise1 * 0.5 + noise2 * 0.3 + noise3 * 0.2;
        
        return finalNoise;
    }
`;
